import React from "react";

function RoiContent() {
  return (
    <div className="container roi-content-wrapper">
      <h2>How to use Email Marketing ROI Calculator?</h2>
      <p>
        The Email Marketing ROI calculator is divided into two major parts. On the left are the input fields where you
        can insert your required parameters for your email marketing campaign. On the right are the results displayed
        based on your provided inputs.&nbsp;
      </p>
      <h3>Inputs for Email ROI Calculator</h3>
      <h4>Currency</h4>
      <p>
        In this field, you need to select the currency used in your email marketing campaign. USD ($) is the default
        currency.&nbsp;
      </p>
      <h4>Email Send Volume&nbsp;</h4>
      <p>
        Enter the total number of emails you wish to send for the email marketing campaign. For example, 100,000 if you
        are sending 100K emails.&nbsp;
      </p>
      <h4>Cost of Email Marketing Campaign&nbsp;</h4>
      <p>
        Cost includes the total price you are paying for your email marketing campaign. For example, if you are paying
        $1,500 for sending 100,000 emails, enter 1,500 in this field.
      </p>
      <h4>Open Rate</h4>
      <p>
        This is the percentage of recipients who opened your email. You can make an approximation based on your previous
        email marketing campaigns. On average, the opening rate is between 15 to 25%.&nbsp;
      </p>
      <h4>Click Through Rate (CTR)</h4>
      <p>
        Not everyone who opens your email will click on the link that redirects &nbsp;to your service landing page.
        Click through rate (CTR) is the percentage of people that clicked and opened your landing page provided on
        email. This is an approximate rate that you need to provide based on your previous campaigns. This rate may vary
        based on the campaign you run and your industry. On average, the CTR is between 2 to 10%.
      </p>
      <h4>Conversion Rate</h4>
      <p>
        Once the user &nbsp;lands on your service page, the rate of people purchasing your product or service is the
        conversion rate. You can check the conversion rate from google analytics and try to use that value in this field
        to get a better approximate result.
      </p>
      <h4>Average Value of a Conversion</h4>
      <p>
        The service cost that you are providing to your clients may vary based on the package they are purchasing. In
        this field you need to provide an average value from all the sales from different categories divided by the
        number of consumers. If you have a single price value like $10, you can insert this value here.&nbsp;
      </p>
      <h3>Results Section</h3>
      <p>
        The Results section provides the final result of your email marketing campaign based on the inputs you have
        provided.&nbsp;
      </p>
      <h4>Total Opens</h4>
      <p>In this section, You will find the total number of subscribers that might open your email.</p>
      <h4>Total Clicks</h4>
      <p>
        This field shows the total number of clicks you might get from the marketing campaign. i.e. total number of
        people who came to your landing page.
      </p>
      <h4>Total Expected Conversion</h4>
      <p>This field shows the total number of people who might purchase your product or service.</p>
      <h4>Total Revenue</h4>
      <p>
        This is the total revenue you generated from your email marketing campaign. This value is the product of
        &ldquo;total expected conversions&rdquo; and &ldquo;average value of a conversion&rdquo;.
      </p>
      <h4>Total Profit</h4>
      <p>
        In this section, you will get the overall expected profit from the campaign. The profit is calculated by
        subtracting the &ldquo;total cost of email marketing campaign&rdquo; from &ldquo;total revenue&rdquo; generated.
      </p>
      <h4>Conversion to Breakeven</h4>
      <p>
        This field provides the total number of conversions you need to have to avoid any loss. You need to have at
        least this much of conversions to be at neutral i.e. neither loss nor profit from this campaign. This value is
        calculated by dividing the &ldquo;cost of email marketing&rdquo; by &ldquo;average value of a conversion&rdquo;.
      </p>
      <h4>Return On Investment (ROI)</h4>
      <p>
        ROI is the percentage return on your investment. For example, if you invested $1,500 on your email marketing
        campaign and the total revenue you generated is $2,500, the final ROI is 66.67%.&nbsp;
      </p>
    </div>
  );
}

export default RoiContent;
