import styled from "styled-components";
import colors from "../style/colors";

const P1 = styled.p`
  font-size: 1.375em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.dark)};
  margin: ${props => (props.margin ? props.margin : "0.3em 0 1.3em")};
  font-weight: 500;
  line-height: 1.6;
  @media (max-width: 640px) {
    font-size: 1.3em;
  }
  ${props =>
    props.serif &&
    `
    font-family: Lora, "Palatino Linotype", "Book Antiqua", Palatino, Georgia,
      serif;
    color: ${colors.ultra_dark};
    margin: 0.3em 0 0.8em;
    font-weight: 400;
    line-height: 1.8;
    font-size: 1.15em;
  `}
`;

export default P1;
