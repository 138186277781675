import React from "react";
import { Helmet } from "react-helmet";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import RoiCalculatorPage from "../sections/RoiCalculator";
import RoiContent from "../sections/RoiContent";

function RoiCalculator(props) {
  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="Email Marketing ROI Calculator | Boxy Suite 2" canonical={props.location.href} />
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>

        <SiteHeader dark />
        <RoiCalculatorPage />
        <RoiContent />
        <Footer />
      </LayoutWrapper>
    </>
  );
}

export default RoiCalculator;
